import Login from './Login/Login';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import { CookiesProvider } from "react-cookie";
import { useCookies } from 'react-cookie';
import Redirect from './redirect/redirect';


const App = () => {
	const [cookies] = useCookies(['token']);
    const isTokenValid = function () {
        try{
            if(cookies.token !== undefined){
                const jwtPayload = JSON.parse(window.atob(cookies.token.split('.')[1]))
                const dateToken = new Date((jwtPayload.exp * 1000));
                const dateNow = new Date();        
                return dateNow < dateToken;
            }
            return false;     
        }catch(e){
            console.log(e);
            return false;
        }
    }

    const siteKey = process.env.REACT_APP_SITE_KEY as string; 
    if(isTokenValid() !== true){
        return(
        <GoogleReCaptchaProvider reCaptchaKey={siteKey}>  
            <CookiesProvider>
                <Login></Login>
            </CookiesProvider>             
        </GoogleReCaptchaProvider>  
        );
    }else{
        return(
            <Redirect></Redirect>
        );
    }
}

export default App;