import Swal from 'sweetalert2';
import Chatweb from '../ChatWeb/Chatweb';

const Redirect = () => {

    Swal.fire({
        title: 'Verificando informações',
        html: 'Aguarde enquando os dados são validados',
        timer: 2500,
        icon: 'success',
        timerProgressBar: true,
        showConfirmButton: false,
        allowOutsideClick: false,
        backdrop: '#243464',
        didOpen: () => {
            Swal.showLoading(Swal.getDenyButton());
        },
    }).then((result) => {
        /* Read more about handling dismissals below */
        if (result.dismiss === Swal.DismissReason.timer) {
            //window.location.replace('https://nuvemauth.z15.web.core.windows.net/');
            //window.location.replace('https://grupomoura.chat.blip.ai/?appKey=cm90ZWFkb3J3ZWJjaGF0OjRkNjdhOTEwLWZhNTktNDMwNi05ZDBlLTU3Y2M1NzNhOTA5Yg==');
        }
    });
    
    return(        
        <div  className="container-princpal"><Chatweb></Chatweb></div>
    );
}

export default Redirect;