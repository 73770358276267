import 'bootstrap/dist/css/bootstrap.min.css';
import './Login.css';
import logo_moura from '../../images/logo_moura.png';
import LoginService from '../../service/LoginService';
import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCookies } from 'react-cookie';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';

//react Hook Form
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

//Yup
import * as yup from "yup";

const schema = yup.object({
	user: yup.string().required("Campo obrigatório"),
	password: yup.string().required("Campo obrigatório"),
}).required();
type FormData = yup.InferType<typeof schema>;

const Login = () => {
	const date = new Date();
	const currentYear = date.getFullYear();
	const { register, handleSubmit, watch, formState: { errors } } = useForm<FormData>({resolver: yupResolver(schema)});// constates que trata o form validation
	const service = new LoginService();
	const [user, setUser] = useState("");
	const [password, setPassword] = useState("");
	const [contato, setContato] = useState("");
	const navigate = useNavigate();	
	const [cookes, setCookie] = useCookies(['token']);
	const { executeRecaptcha } = useGoogleReCaptcha();

	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const btn = document.getElementById("btn-enter");
	const span = document.getElementById("span-title");
	const icon = document.getElementById("icone-enter");
	const controlBtnEnter = () =>{
		icon?.classList.remove('bi-box-arrow-in-right');
		icon?.classList.add('bi-arrow-clockwise');
		btn?.setAttribute('disabled','true');
		span!.textContent = 'Carregando';

		console.log('entrou no metodo');
	}

	const controlBtnEnterRemove = () =>{
		btn?.removeAttribute("disabled");
		icon?.classList.remove('bi-arrow-clockwise');
		icon?.classList.add('bi-box-arrow-in-right');
		span!.textContent = 'Entrar';

		console.log('entrou no metodo');
	}
	//pega o evento do botao para iniciar o atendimento desabilitar conta
	const handleChange = (evento: any ) => {
		
		let valor = evento.target.value;
		setContato(valor);
		if(valor.includes("@grupomoura.com") || valor.includes("@bateriasmoura.com") || valor.includes("@icmoura.org") || valor.includes("@itemm.org.br") || valor.includes("@propig.com.br")){
			document.getElementById("btn-chat")?.classList.remove('disabled')
			
		}
		else{
			document.getElementById("btn-chat")?.classList.add('disabled')
		}
		return valor;
	};
	//funcao de click para envio do contato
	const handleButtonClick = () => {
			// Navegar para a tela de destino com os dados como props
			navigate('/chatdesbloqueio',{ state: { contato } });
	};

	const handleSumitForm = 
		(e: any) => {
			var email = user;
			var senha = password;
			controlBtnEnter();
			
			//e.preventDefault();
			if (!executeRecaptcha) {
				console.log("Execute recaptcha not yet available");
				
				return;
		  	}
			executeRecaptcha("enquiryFormSubmit").then(async (gReCaptchaToken: any) => {
				const handleCookie = (gReCaptchaToken: any) => {
					setCookie('token', gReCaptchaToken, { path: '/', maxAge: 10800 });	
				};
				
				var token = await service.login(email,senha,gReCaptchaToken);
				if(token !== undefined && token.length > 0){
					handleCookie(token);
				}
				else{
					controlBtnEnterRemove();
				}
		  	})
		}

  return (
	<div className="container-principal">
		<div className="container-central">
			<div className="container-login">
				<img className="logo-moura" src={logo_moura} alt="logo_moura"/>
				<form className="login-form" id="form-login" onSubmit={handleSubmit(handleSumitForm)}>
					<div className="login-input margin-top-35 margin-bottom-35" >
						<input
							type="email"
							{...register("user",{ required: true })}
							value={user}
							onChange={(e) => setUser(e?.target?.value)}
							className="input-form"
							placeholder="E-mail"
						/>
						<span className="focus-input-form error">{errors.user?.message}</span>
					</div>
					<div className="login-input margin-bottom-35">
						<input
							type="password"
							{...register("password",{ required: true })}
							value={password}
							onChange={(e) => setPassword(e?.target?.value)}
							className="input-form"
							placeholder="Senha"
						/>
						<span className="focus-input-form error">{errors.password?.message}</span>
					</div>
					<div className="container-login-form-btn">
						<button type="submit" className="login-form-btn" id="btn-enter">
							<span id="span-title">Entrar</span> &nbsp;<i id="icone-enter" className="bi bi-box-arrow-in-right"></i>
						</button>
					</div>
					<div className="mt-3">
					<a onClick={handleShow} id="esqueci-senha"><i className="bi bi-info-circle" title='Esqueci minha senha'></i>&nbsp;<b>Esqueci minha senha</b></a>
					</div>		
				</form>
				<div className='text-center'>
					<span>{currentYear} © Acumuladores Moura SA.</span>
				</div>
				
				<Modal show={show} onHide={handleClose} centered>
        			<Modal.Header closeButton id="header-modal">
          				<Modal.Title id="title-modal"><i className="bi bi-key"></i>&nbsp;Recuperação de senha & Desbloqueio de conta de rede</Modal.Title>
        			</Modal.Header>
        			<Modal.Body id="body-Modal">
					<b><i className="bi bi-caret-right-fill"></i>&nbsp;Desbloqueio de Conta</b><br/><br/>
					<div className="text-center">
						<Form>
							<Form.Label><b>Informe seu Email Corporativo</b></Form.Label>
        					<Form.Control type="email" name="contato" placeholder="Email Coporativo" id="email-cliente" onChange={handleChange}/><br/>
							<Form.Text className="text-muted">
          						<b>Ex:</b> @grupomoura.com ou @bateriasmoura.com
        					</Form.Text><br/><br/>
							<Button onClick={handleButtonClick} type="submit" className="btn btn-success disabled" id="btn-chat">Falar com um atendente&nbsp;<i className="bi bi-chat-left-text-fill"></i></Button>
							
						</Form>
					</div>
					<br/><br/>
					<b><i className="bi bi-caret-right-fill"></i>&nbsp;Reset de Senha de Rede</b>
					<p>Em caso de reset de senha favor, realizar abertura de chamado via SDM no link a baixo.</p>
					<div className="text-center">
						<Button variant="primary" id="redirect-sdm" target="_blank" title="Clique aqui para abrir o chamado"
						href="https://atendimentomoura-myit.onbmc.com/dwp/rest/share/OJSXG33VOJRWKVDZOBST2U2SIQTHIZLOMFXHISLEHVAUOR2CG5KVAU2DIVMUYN2BKI4FIMBQHBJDQVBQGA4FIMKMIUTHEZLTN52XEY3FJFSD2U2SI5AUCNKWGBDDCNCOHBAVATKLGZHEMUCMJY2VUU2RGAZECJTDN5XHIZLYORKHS4DFHVBUCVCBJRHUOX2IJ5GUKJTQOJXXM2LEMVZFG33VOJRWKTTBNVST243SNU======">
						Abrir chamado SDM&nbsp;<i className="bi bi-browser-chrome"></i></Button>
					</div>	
					</Modal.Body>
        			<Modal.Footer>
          				<Button variant="secondary" onClick={handleClose}>
            				Voltar&nbsp;<i className="bi bi-box-arrow-left"></i>
          				</Button>
        			</Modal.Footer>
      			</Modal>
			</div>	
		</div>
				
	</div>
	
  );
};

export default Login;