import axios from 'axios';
import Swal from 'sweetalert2';

class LoginService {
    Alerta(mensagem:string,tipo:any):void {
        Swal.fire({
            position: 'top-end',
            icon: tipo,
            title: mensagem,
            showConfirmButton: false,
            timer: 1000
        }); 
    }

    login = async (email:string,senha:string,tokenRecap:string) => {      
        var token = '';
        await axios({
            method: "POST",
            headers: {
                "accept": "*/*",
                "Content-Type": "application/json"
            },
            //baseURL : 'https://localhost:7040/SsoGc/Auth',
            baseURL : 'https://back-autenticador-dev.azurewebsites.net/SsoGc/Auth',
            data: {
                email: email,
                senha: senha,
                TokenRecaptcha: tokenRecap
            }
        })
        .then( (response) => {
            if(response.data.responseGc === true ){
                    token = response.data.token;
                    this.alertSuccess();
                }
                else{
                    this.alertError();
                }                          
        })
        .catch(function (error) {
            console.error(error);
        });
        return token;
    }

    private alertError() {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
          
        Toast.fire({
            icon: 'error',
            title: 'usuário e/ou senha incorretos!'
        });
    }

    private alertSuccess() {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
          
        Toast.fire({
            icon: 'success',
            title: 'Bem vindo!'
        });

        setTimeout(function () {
            window.location.reload();
            //window.location.replace('https://nuvemauth.z15.web.core.windows.net/');
            //window.location.replace('https://grupomoura.chat.blip.ai/?appKey=cm90ZWFkb3J3ZWJjaGF0OjRkNjdhOTEwLWZhNTktNDMwNi05ZDBlLTU3Y2M1NzNhOTA5Yg==');
        }, 2000);
    }
}

export default LoginService