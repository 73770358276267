import "./ChatDesbloqueio.css";
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { BlipChat } from "blip-chat-widget";

const ChatDesbloqueio = ({blipKey, blipUrl, blipUrlIcon, getIpPublic}) =>{
    const location = useLocation();
    const { contato } = location.state;
    var ip;
    function recarregarChat(){
        var builder = new BlipChat();
        async function getIpClient() {
            try {
              const response = await axios.get(getIpPublic);
                ip = response.data.ip
                builder.withAppKey(blipKey)
                .withButton({"color":"#243464","icon":blipUrlIcon})
                .withCustomCommonUrl(blipUrl)
                .withTarget("Chatbot-desbloqueio")
                .withoutHistory()
                .withEventHandler(BlipChat.LOAD_EVENT, function () {
                builder.sendMessage({
                    "type": "text/plain",
                    "content": "Olá, meu email é "+{ contato }.contato+" e preciso desbloquear minha conta. Origem do Cliente: "+ip
                })
              })
              .build()
            } catch (error) {
              console.error(error);
            }
          }
        getIpClient();
    };
    recarregarChat();
    return(
        <div id="Chatbot-desbloqueio">
            <div className="text-center mt-5">
            {[
                'primary',
            ].map((variant) => (
                <Alert key={variant} variant={variant}>
                    <span id="info-chat"><i class="bi bi-headset"></i> Olá, para iniciar o atendimento de <b>Desbloqueio de conta</b> clique no botão a baixo!</span>
                </Alert>
            ))}
                <Button variant="primary" onClick={recarregarChat}><i class="bi bi-arrow-clockwise"></i>&nbsp;Recarregar chat</Button>
            </div>
        </div>
    )
}

export default ChatDesbloqueio;