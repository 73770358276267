import './Chatweb.css';
import Button from 'react-bootstrap/Button';
import Iframe from 'react-iframe';
import Cookies from "js-cookie";
import Swal from 'sweetalert2';



const Chatweb = () => {
    
    function logOff(){
        Swal.fire({
            title: 'Você deseja sair?',
            text: "Ao clicar em sim, você será desconectado da central de ajuda!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#243464',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Cancelar', 
          }).then((result) => {
            if (result.isConfirmed) {
              Cookies.remove('token');
              window.location.replace(process.env.REACT_APP_AUTH_AZURE as string);
            }
          })

    }
    return(
        
        <div className='container-chat'>
            <div className="container-logoff">
                <Button  onClick={logOff} id='btn-sair' variant="danger" title="Sair"><i className="bi bi-power"></i>&nbsp;Sair</Button>
            </div>
            <Iframe  url={process.env.REACT_APP_CHAT_AZURE as string} id="webView-chat"/>
        </div>
    );
};

export default Chatweb;